@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    @apply scroll-smooth text-gray-700 antialiased;
    font-family: var(--font-primary), "Arial", "sans-serif";
    font-weight: 400;
    scroll-behavior: smooth;
    /*ui-sans-serif, system-ui, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"*/

    /*
    FIXME: try to use grid?
    */
    body {
      display: flex;

      header + main {
        overflow-x: clip;
      }

      & > #root {
        flex: 1 1 0%;
        display: flex;
        flex-direction: column;
        min-height: 100vh;
      }
    }
  }

  input:focus ~ label,
  input:not(:placeholder-shown) ~ label,
  textarea:focus ~ label,
  textarea:not(:placeholder-shown) ~ label,
  select:focus ~ label,
  /* Remove :not([value=""]) if we would like to always display any option with i.e. default message */
  select:has(option:checked:not([value=""])) ~ label,
  select[size] ~ label {
    @apply transform;
    @apply scale-82;
    @apply -translate-y-[1.5rem];
  }

  input:focus ~ label,
  select ~ label {
    @apply left-0;
  }

  select,
  select option {
    @apply text-ellipsis;
  }
}

@layer components {
  .absolute-center {
    @apply absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2;
  }

  .vertical-center {
    @apply absolute top-1/2 -translate-y-1/2;
  }

  .horizontal-center {
    @apply absolute left-1/2 -translate-x-1/2;
  }

  .skeleton {
    @apply animate-pulse rounded-md bg-gold-100;
  }

  .input {
    @apply input-color mt-0 block w-full appearance-none rounded-md border border-gray-300 bg-transparent px-3.5 py-3 transition-all focus-within:border-gold focus-within:ring-1 focus-within:ring-gold hover:border-gold hover:ring-gold focus:outline-gold disabled:text-gray-300;
  }

  .input-wrapper {
    @apply relative z-0 mb-3 w-full [&:last-child]:mb-0;
  }

  .input-check-shadow {
    @apply before:absolute before:left-2/4 before:top-2/4 before:block before:h-10 before:w-10 before:-translate-x-2/4 before:-translate-y-2/4 before:rounded-full before:opacity-0 before:transition-opacity checked:before:bg-gray-900 hover:before:opacity-10;
  }

  .input-skeleton {
    @apply skeleton col-span-2 mb-3 h-[50px] [&:last-child]:mb-0;
  }

  .floating-label {
    @apply origin-0 input-color pointer-events-none absolute top-3.5 ml-3 bg-white px-1.5 transition-all focus-within:border-gold focus-within:ring-1 focus-within:ring-gold peer-disabled:text-gray-300;
  }

  .items-grid {
    @apply grid w-full grid-cols-1 gap-4 xs:grid-cols-2 md:grid-cols-3 md:gap-3 lg:grid-cols-4 xl:gap-4 2xl:gap-6;
  }

  .carousel-navigation {
    @apply vertical-center top-[45%] flex size-14 cursor-pointer select-none items-center justify-center rounded-full bg-gray-100 transition-colors duration-500 hover:bg-gold hover:text-white [&>svg]:size-4;
  }

  .section {
    @apply mb-10;
  }
}

@layer utilities {
  .inherit-display {
    display: inherit;
  }

  .input-color {
    @apply text-gray-700;
  }

  .no-italic {
    font-style: normal;
  }

  .origin-0 {
    transform-origin: 0;
  }

  .aspect-inherit {
    aspect-ratio: inherit;
  }

  .small-caps {
    font-variant: small-caps;
  }

  .card-gap {
    @apply gap-1 md:gap-2;
  }

  .card-image {
    @apply aspect-[9/12];
  }

  .p-inherit {
    padding: inherit;
  }

  .pointer-events-all {
    pointer-events: all;
  }

  .break-word {
    word-break: break-word;
  }

  .text-align-last-center {
    text-align-last: center;
  }
}
